import { RouteUpdateArgs } from 'gatsby';
import { useStaticQuery, graphql, PageProps, navigate } from "gatsby";

if (process.env.NODE_ENV === 'development') {
	const VConsole = require('vconsole');
	new VConsole();
}

export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
	// 特定のページパス（/telconversion）の場合のみデータを送信
	if (location.pathname.endsWith('/tel_conversion/')) {
		const searchParams = new URLSearchParams(location.search);
		const officeName = location.search ? new URLSearchParams(location.search).get('on') : '';
		const inqId = searchParams.get('id');
		const title = `電話発信[ID:${inqId}] | 労働問題のお悩み相談所(${officeName})`;
console.log(title);
		if (typeof window !== 'undefined') {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'page_view',
				page_location: location.href,
				page_path: location.pathname,
				page_title: title,
			});
			console.log("event pushed.");
		}
	}
	else if (location.pathname.endsWith('/inquirycomplete/')) {
		const inqId = location.search ? new URLSearchParams(location.search).get('id') : '';
		const officeName = location.search ? new URLSearchParams(location.search).get('on') : '';
		const title = `お問い合わせ完了[ID:${inqId}] | 労働問題のお悩み相談所(${officeName})`;
console.log(title);
		if (typeof window !== 'undefined') {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'page_view',
				page_location: location.href,
				page_path: location.pathname,
				page_title: title,
			});
			console.log("event pushed.");
		}
	}
};